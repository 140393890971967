// Initialize Swiper
import Swiper from 'swiper/bundle';
export default function initializeHeroSwiper() {
    // Initialize Swiper with optional parameters
    const heroSlider = new Swiper('.hero__swiper', {
        loop: true,
        // autoplay: {
        //     delay: 6000,
        // },
        pagination: {
            el: '.swiper-pagination.hero-pagination',
        },
        navigation: {
            nextEl: '.swiper-button-next.hero-next',
            prevEl: '.swiper-button-prev.hero-prev',
        },
        on: { 
            slideChange: function () {
                updateNavigationVisibility(); 
            },
        },
    });

    // Handle click on individual pagination bullets
    const paginationBullets = document.querySelectorAll('.swiper-pagination-bullet.hero-pagination');
    paginationBullets.forEach((bullet, index) => {
        bullet.addEventListener('click', () => {
            // Slide to the clicked bullet's index
            heroSlider.slideTo(index);
        });
    });

    // Function to hide or show navigation buttons based on window width
    const updateNavigationVisibility = () => {
        const navigationEls = document.querySelectorAll('.swiper-button-next.hero-next, .swiper-button-prev.hero-prev');
        const shouldHide = window.innerWidth <= 1023 || heroSlider.slides.length <= 1;

        navigationEls.forEach((el) => {
            // el.style.display = shouldHide ? 'none' : 'block';
            el.style.display = shouldHide ? 'none' : 'none';
        });
    };

    // Function to hide or show .hero-pagination based on window width
    const updateHeroPaginationVisibility = () => {
        const heroPagination = document.querySelector('.hero-pagination');
        if (heroPagination) {
            const shouldHide = window.innerWidth <= 1023 || heroSlider.slides.length <= 1;
            // heroPagination.style.display = shouldHide ? 'block' : 'none';
            heroPagination.style.display = shouldHide ? 'none' : 'none';
        }
    };

    // Call the navigation and hero pagination visibility functions on page load and window resize
    updateNavigationVisibility();
    updateHeroPaginationVisibility();
    window.addEventListener('resize', () => {
        updateNavigationVisibility();
        updateHeroPaginationVisibility();
    });

}