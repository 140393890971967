import $, { css, get } from "jquery";
$(function () {
  const $menu = $('.menu');
  const $menuSection = $menu.find('.menu-section');
  const $menuArrow = $menu.find('.menu-mobile-arrow');
  const $menuClosed = $menu.find('.menu-mobile-close');
  const $menuToggle = $('.menu-mobile-toggle');
  const $menuToggleSpan = $menuToggle.find('span');
  const $menuOverlay = $('.overlay');
  let $subMenu;

  function toggleMenu() {
    $menu.toggleClass('active');
    $menuOverlay.toggleClass('active');
    $menuToggle.toggleClass('active-humburger');
    $menuToggleSpan.toggleClass('active');

    $('body').toggleClass('menu-open');
  }

  function showSubMenu($hasChildren) {
    $subMenu = $hasChildren.find('.menu-subs');
    $subMenu.removeClass('slide-right').addClass('active slide-left');

    const menuTitle = $hasChildren.find('.main-link').text().trim();
    if (menuTitle == 'Motion SimulatorsRacing SimulatorsFlight Simulators') {
      $menu.find('.menu-mobile-title').html('Motion Simulators');
    }
    else {
      $menu.find('.menu-mobile-title').html(menuTitle);
    }


    // $menu.find('.menu-mobile-title').html(menuTitle);
    $menu.find('.menu-mobile-header').addClass('active');
    $menu.find('.menu-section').addClass('menu-section-sub');
  }

  function hideSubMenu() {
    $subMenu.removeClass('slide-left').addClass('slide-right');
    setTimeout(function () {
      $subMenu.removeClass('active');
    }, 300);
    $menu.find('.menu-mobile-title').html('');
    $menu.find('.menu-mobile-header').removeClass('active');
    $menu.find('.menu-section').removeClass('menu-section-sub');
  }

  function addFunctionality() {
    $('.menu-item-has-children').on('click', function (e) {
      e.stopPropagation();
      const $this = $(this);
      const target = $this.data('target');
        $('.menu-subs').removeClass('open-menu').parent().removeClass('active');
        $('#' + target).addClass('open-menu').parent().addClass('active');
        $('header').addClass('header-open-menu');
        $('body').addClass('fixed-position');
        if(!$(".under-menu-bg").attr("class")){
        	$('<div class="under-menu-bg"></div>').insertBefore('.wrapper');
        }
    });

    $(document).on('click', function () {
      if (!$('body').hasClass('popup-open')) {
          $('.menu-subs').removeClass('open-menu').parent().removeClass('active');
          $('header').removeClass('header-open-menu');
          $('div').remove('.under-menu-bg');
      }
    });

    $('.close-menu').on('click', function (e) {
      e.stopPropagation();
      if (!$('body').hasClass('popup-open')) {
          $('.menu-section').find('.menu-subs').removeClass('open-menu').parent().removeClass('active');
          $('header').removeClass('header-open-menu');
          $('body').removeClass('fixed-position');
          $('div').remove('.under-menu-bg');
      }
    });
  }

  // function addFunctionality() {
  //     $('.menu-item-has-children').on('click', function (e) {
  //         e.stopPropagation();
  //         const $this = $(this);
  //         const target = $this.data('target');
  //         $('.menu-subs').removeClass('open-menu').parent().removeClass('active');
  //         $('#' + target).addClass('open-menu').parent().addClass('active');          
  //     });

  //     $(document).on('click', function () {
  //         if (!$('body').hasClass('popup-open')) {
  //             $('.menu-subs').removeClass('open-menu').parent().removeClass('active');            
  //         }
  //     });

  //     $('.close-menu').on('click', function (e) {
  //         e.stopPropagation();
  //         if (!$('body').hasClass('popup-open')) {
  //             $('.menu-section').find('.menu-subs').removeClass('open-menu').parent().removeClass('active');
  //         }
  //     });
  // }

  function handleSubMenuClick(e) {
    e.stopPropagation();
    $('.menu-subs').removeClass('open-menu');
  }

  $menuSection.on('click', function (e) {
    if (!$menu.hasClass('active')) {
      return;
    }
    const $hasChildren = $(e.target).closest('.menu-item-has-children');
    if ($hasChildren.length) {
      showSubMenu($hasChildren);
    }
  });

  $menuArrow.on('click', hideSubMenu);
  $menuToggle.on('click', toggleMenu);
  $menuClosed.on('click', toggleMenu);
  $menuOverlay.on('click', toggleMenu);
  $('.sims-games-menu-link').on('click', handleSubMenuClick);

  $(window).on('resize', function () {
    const width = $(this).width();
    if (width >= 993) {
      addFunctionality();
    } else {
      $('.menu-item-has-children').off('click');
      $(document).off('click');
      $('.close-menu').off('click');
    }
  });

  // Check screen width on initial page load
  if ($(window).width() >= 993) {
    addFunctionality();
  }
});
