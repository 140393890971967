import Swiper from 'swiper/bundle';
export default function initializeBigSwiper() {
    var swiper = new Swiper('.swiper-ml-container', {
        slidesPerView: 'auto',
        loop: true,
        autoplay: {
            delay: 4000,
        },
        //spaceBetween: 20, /* Adjust the space between slides as needed */
        navigation: {
            nextEl: '.swiper-button-next.ms-slider-next',
            prevEl: '.swiper-button-prev.ms-slider-prev',
        },
        // pagination: {
        //   el: '.swiper-pagination',
        // },
        on: {
            slideChange: function () {
                updateSlideVisibility(swiper);
            },
        },
    });
}

function updateSlideVisibility(swiper) {
    var slides = swiper.slides;

    slides.forEach(function (slide) {
        slide.classList.remove('swiper-slide-visible');
    });

    var activeIndex = swiper.activeIndex;

    slides[activeIndex].classList.add('swiper-slide-visible');
    if (slides[activeIndex + 1]) {
        slides[activeIndex + 1].classList.add('swiper-slide-visible');
    }
}