import $, { css, get } from "jquery";
import Swiper from 'swiper/bundle';
export default function initializeOptionPopupSlider() {
    $('.block-button').magnificPopup({
        delegate: 'a',
        removalDelay: 500,
        fixedContentPos: true,
        callbacks: {
            beforeOpen: function () {
                this.st.mainClass = this.st.el.attr('data-effect');
            },
            open: function () {            	
                $(this.container).addClass('option-popup-open');
                $('body').find('.mfp-bg').removeClass('mfp-bg');
                const clickedButton = $(this.currItem.el[0]);
                const blockNumber = clickedButton.data('block-number');
                const popupSwiper = new Swiper('.swiper-options-popup', {
                    loop: true,
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    spaceBetween: 32,
                    // initialSlide: blockNumber,
                    navigation: {
                        nextEl: '.swiper-button-next.option-next',
                        prevEl: '.swiper-button-prev.option-prev',
                    },
                    scrollbar: {
                        el: '.swiper-scrollbar',
                        hide: false, // Show the scrollbar
                    },
                    on: {
                        slideChange: function () {
                            const totalSlides = this.slides.length;
                            const activeIndex = this.realIndex; // Get the real index of the active slide
                            this.slides.forEach((slide, index) => {
                                // Calculate the correct serial number for each slide
                                let slideNumber = (index - activeIndex) % totalSlides;
                                if (slideNumber < 0) {
                                    slideNumber += totalSlides; // Ensure non-negative value for slideNumber
                                }
                                slideNumber += 1; // Increment to start from 1
                                const blockNumber = $(slide).data('block-number') + 1; // Increment blockNumber to start from 1
                                $(slide).find('.slider-counter__option').text(blockNumber + '/' + totalSlides);
                            });
                        }
                    }
                });

                // Find the slide with the matching data-block-number
                const matchingSlideIndex = $(`.swiper-slide[data-block-number="${blockNumber}"]`).index();               

                popupSwiper.slideTo(matchingSlideIndex, 0);
                // Adjust spaceBetween value based on screen width
                function updateSpaceBetween() {
                    if (window.innerWidth <= 992) {
                        popupSwiper.params.spaceBetween = 10; // Adjust space between for screens up to 992px width
                    } else {
                        popupSwiper.params.spaceBetween = 32; // Adjust space between for screens wider than 992px
                    }
                    popupSwiper.update(); // Update Swiper to reflect the changes
                }

                // Call the function initially and whenever the window is resized
                updateSpaceBetween();
                window.addEventListener('resize', updateSpaceBetween);
            }
        },
        // midClick: true
    });

    // Function to update checkbox state
    function updateCheckboxState(productId, isChecked) {
        const $checkboxes = $(".option-list").find(`.addToCartCheckbox[data-product-id="${productId}"]`);        	
        $checkboxes.each(function () {
            const $checkbox = $(this);
            $checkbox.prop('checked', isChecked);

            const $optionListInner = $checkbox.closest('.option-list__inner');
            if (isChecked) {
                $optionListInner.addClass('checked');
            } else {
                $optionListInner.removeClass('checked');
            }

            const newState = isChecked ? 'checked' : 'unchecked';
            localStorage.setItem(`addToCartCheckboxState_${productId}`, newState);
        });
    }

    // Function to update checkbox state
    function updateCheckboxStatePopup(productId, isChecked) {
        const $checkboxes = $(".option-popup-swiper-slide").find(`.addToCartCheckbox[data-product-id="${productId}"]`);        
        $checkboxes.each(function () {        	
            const $checkbox = $(this);
            $checkbox.prop('checked', isChecked);
            // Find the swiper-slide with a specific date attribute value                
             const $optionTextBtn = $checkbox.parent().find('.option__text--btn .option__text');
            // Check if the element was found
            if (isChecked) {
                $optionTextBtn.text('Remove Item');
                $optionTextBtn.parent().addClass('remove-item');
            } else {
                $optionTextBtn.text('Add to Cart');
                $optionTextBtn.parent().removeClass('remove-item');
            }
        });
    }

    
    // Function to handle checkbox change event
    // function handleCheckboxChange($checkbox) {
    //     const productId = $checkbox.data('product-id');
    //     const productType = $checkbox.data('product-type');

    //     const isChecked = $checkbox.prop('checked');
    //     console.log(isChecked);

    //     // Find the closest .swiper-slide element to the checkbox
    //     const $containerSwiperSlide = $checkbox.closest('.swiper-slide');
    //     const $optionTextBtn = $containerSwiperSlide.find('.option__text--btn .option__text');
    //     // Update text based on isChecked
    //     if (isChecked) {
    //         $optionTextBtn.text('Remove Item');
    //         $optionTextBtn.parent().addClass('remove-item');
    //     } else {
    //         $optionTextBtn.text('Add to Cart');
    //         $optionTextBtn.parent().removeClass('remove-item');
    //     }
    //     updateCheckboxState(productId, isChecked);

    //     if (isChecked) {
    //         addToCart(productId, productType);
    //     } else {
    //         removeFromCart(productId);
    //     }
    // }
    function getWarrantyOptionIds(excludeId) {
        var warrantyOptionIds = [];
        $('.option-list__row').each(function() {
            $(this).find('.option-list__col.enable-warranty').each(function() {
                var optionId = $(this).data('option-id');
                if (optionId !== excludeId) {
                    localStorage.setItem(`addToCartCheckboxState_${optionId}`, 'unchecked');
                    warrantyOptionIds.push(optionId);
                }
            });
        });
        return warrantyOptionIds;
    }
    

    function handleCheckboxChange($checkbox) {
        const productId = $checkbox.data('product-id');
        const productType = $checkbox.data('product-type');
        const enableWarranty = $checkbox.data('enable-warranty');

        const isChecked = $checkbox.prop('checked');
        console.log(isChecked);

        // Find the closest .swiper-slide element to the checkbox
        const $containerSwiperSlide = $checkbox.closest('.swiper-slide');
        const $optionTextBtn = $containerSwiperSlide.find('.option__text--btn .option__text');

        // Update text based on isChecked
        if (isChecked) {
            $optionTextBtn.text('Remove Item');
            $optionTextBtn.parent().addClass('remove-item');
        } else {
            $optionTextBtn.text('Add to Cart');
            $optionTextBtn.parent().removeClass('remove-item');
        }       
        if (isChecked && !enableWarranty) {
            addToCart(productId, productType,isChecked);
        } else if (isChecked && enableWarranty) {
            $('.option-list__row .option-list__col input[type="checkbox"][data-enable-warranty="enable-warranty"]').not($checkbox).prop('checked', false).parent().removeClass('checked');
            getWarrantyOptionIds(productId);
            addToCart(productId, productType,isChecked);
        } else {
            removeFromCart(productId,isChecked);
        }
    }


    // Initialize checkbox states from local storage
    $('.option-list__inner').each(function () {
        const $checkbox = $(this).find('.addToCartCheckbox');
        const productId = $checkbox.data('product-id');
        const savedState = localStorage.getItem(`addToCartCheckboxState_${productId}`);        
        if (savedState === 'checked') {
            updateCheckboxState(productId, true);
        }
    });

    $('.option-popup-swiper-slide').each(function () {
        const $checkbox = $(this).find('.addToCartCheckbox');        
        const productId = $checkbox.data('product-id');
        const savedState = localStorage.getItem(`addToCartCheckboxState_${productId}`);        
        if (savedState === 'checked') {        	
            updateCheckboxStatePopup(productId, true);
        }
    });

    
    // Checkbox change event (for changes outside the current context)  
    $('.addToCartCheckbox').on('change', function () {
        handleCheckboxChange($(this));
    });

    function updateTotalPrice() {
        var totalPrice = 0;
        var warrantyPercentage = 0;
        var totalCheckedPrice = 0;
        var totalCheckedPriceW = 0;
        var currency = $(".site-content").data('currency'); 
        if ($('.page-price').length > 0) {
            // Check if .page-price__sale element exists
            if ($('.page-price__sale').length > 0) {
                // Extract sale price
                var pagePrice = parseFloat($('.page-price__sale').text().replace(currency, '').replace(/\s/g, ''));
            } else {
                // If .page-price__sale does not exist, take regular price
                var pagePrice = parseFloat($('.page-price').find('span').text().replace(currency, '').replace(/\s/g, ''));
            }
        }
        // Calculate the page price
        var pagePrice = 0;
        if ($('.page-price').length > 0) {
            if ($('.page-price__sale').length > 0) {
                pagePrice = parseFloat($('.page-price__sale').text().replace(currency, '').replace(/\s/g, ''));
            } else {
                pagePrice = parseFloat($('.page-price').find('span').text().replace(currency, '').replace(/\s/g, ''));
            }
        }

        $('.option-list__row .option-list__col').each(function () {
            var $label = $(this).find('label');
            var optionPrice = $(this).data('option-price');
            var isChecked = $label.hasClass('checked');
            var isWarrantyEnabled = isChecked && $(this).data('enable-warranty') === 'enable-warranty';
            var optionWarrantyPercentage = isWarrantyEnabled ? parseFloat($(this).data('enable-warranty-percentage')) : 0;
            var option_of_our_partners = $(this).data('option-of-our-partners');
            
            if (isChecked) {
                if (optionPrice) {
                    totalCheckedPrice += parseFloat(optionPrice);
                }
                if(option_of_our_partners == "our_option"){
                	warrantyPercentage += optionWarrantyPercentage;
                	totalCheckedPriceW += parseFloat(optionPrice);
                }
            }
        });
        // Calculate the warranty percentage based on the total checked price
        var warrantyPercentageOfTotal = warrantyPercentage * (totalCheckedPriceW / 100);
        // Now adjust the total price based on warranty percentage of total checked price
        
        totalPrice = totalCheckedPrice + warrantyPercentageOfTotal;
        // Deduct percentage from total price
        var deductionAmount = warrantyPercentage * (pagePrice / 100);
        totalPrice += deductionAmount;

        var numberOfElements = $('.option-list__row .option-list__col label.checked').length;
        if (numberOfElements > 0) {
            totalPrice += pagePrice;
        }

        if (totalPrice == 0) {
            $('.sliding-bar .sliding-bar__container').find('.sliding-bar__price').html('<span class="woocommerce-Price-amount amount"><bdi ><span class="woocommerce-Price-currencySymbol">'+currency+'</span>' + Math.round(pagePrice) + '</bdi></span>');
        } else {
            $('.sliding-bar .sliding-bar__container').find('.sliding-bar__price').html('<span class="woocommerce-Price-amount amount"><bdi ><span class="woocommerce-Price-currencySymbol">'+currency+'</span>' + Math.round(totalPrice) + '</bdi></span>');
        }
        if (numberOfElements > 0) {
            $('.sliding-bar .sliding-bar__container .options-numbe__wrap').find('.options_number').text('+' + numberOfElements);
            $('.block-button.options-popup.sliding-bar-popup').hide();
            $('.options-numbe__wrap.options-number__hide').show();
            $('.options-numbe__wrap').show();

        } else {
            $('.block-button.options-popup.sliding-bar-popup').show();
            $('.options-numbe__wrap.options-number__hide').hide();
            $('.options-numbe__wrap').hide();
        }
    }

    function addToCart(productId, productType,isChecked) {
        const option = $('.option-' + productId); // Check that this selector works
        const $messageContainer = option.find('.add-to-cart-message');
        const currentProductId = $('.site-content').find('.product').data('current-product-id');
        
        $.ajax({
            url: wc_add_to_cart_params.ajax_url,
            type: 'POST',
            data: {
                action: 'dof_add_to_cart',
                product_id: productId,
                product_type: productType,
                current_product_id: currentProductId,
            },
            success: function (response) {
                // if (response.data.simulator_in_cart) {
                //     $('.sliding-bar__btn').load(location.href + ' .sliding-bar__btn__link');
                // }
                // if (response.data.simulator_in_cart == '') {
                //     $('.sliding-bar__btn').load(location.href + ' .sliding-bar__btn__link');
                // }

                if (response.success) {
                    $messageContainer.html('<p>' + response.data.message + '</p>');
                    $messageContainer.fadeIn().delay(2000).fadeOut();
                    $('.header-item-right').load(location.href + ' .header-item-right');
                    // $('#comparative-popup').load(location.href + ' #comparative-popup');
                    $('.comparative-popup__main_cart .dof-cart__products').load(location.href + ' .dof-cart__product__inner');
                    // Reload the subtotal value only
                    $('.comparative-popup__main_cart .cart-product__info .cart-product__info-text .subtotal').html(response.data.subtotal);
                    // $('.comparative-popup__main_cart .cart-product__info .cart-product__info-text .subtotal').load(location.href + ' .subtotal');
                    updateCheckboxStatePopup(productId, isChecked);
                    updateCheckboxState(productId, isChecked);
                }
                else {
                    $messageContainer.html('<p class="error">' + response.data.message + '</p>');
                    $messageContainer.fadeIn().delay(2000).fadeOut();
                    // const localStorageKey = 'addToCartCheckboxState_' + productId;
                    // Uncheck the checkbox and remove the 'checked' class after 2 seconds                 
                    // setTimeout(function () {
                    //     option.find('.addToCartCheckbox').prop('checked', false);
                    //     option.find('.checked').removeClass('checked');
                    //     localStorage.setItem(localStorageKey, 'unchecked');
                    // }, 2000);
                }
                updateTotalPrice();
            }
        });
    }

    if ($('body').hasClass('single-product')) {
        updateTotalPrice();
    }

    function removeFromCart(productId,isChecked) {
        const currentProductId = $('.site-content').find('.product').data('current-product-id');
        updateTotalPrice();
        $.ajax({
            url: wc_add_to_cart_params.ajax_url,
            type: 'POST',
            data: {
                action: 'remove_from_cart',
                product_id: productId,
                current_product_id: currentProductId,
            },
            success: function (response) {
                $('.header-item-right').load(location.href + ' .header-item-right');
                if (response.data.is_cart_empty == true && response.data.count_options == 0) {
                    $('.sliding-bar__btn').load(location.href + ' .sliding-bar__btn__link_add');
                }
                updateCheckboxStatePopup(productId, isChecked);
                updateCheckboxState(productId, isChecked);
                updateTotalPrice();
            }
        });
    }
}

