import $, { css, get } from "jquery";
$(function ($) {

    // Wrap items for all tabs on initial page load
    wrapItemsForAllTabs();


    let page = 2;
    let isLoading = false;
    const loadMoreButton = $("#load-more-button");
    const ajaxResponseContainer = $(".games__info");
    const screenWidth = window.innerWidth;
    loadMoreButton.on("click", function () {
        let pageId = $(this).attr('id-page');
        let maxPages = $(this).attr('max-pages');

        if (!isLoading) {
            isLoading = true;
            loadMoreButton.text("Loading...");

            $.ajax({
                url: sg_ajax_obj.ajax_url,
                type: "post",
                data: {
                    action: "load_more_games",
                    page_id: pageId,
                    page: page,
                    nonce: sg_ajax_obj.nonce,
                    slug:location.href
                },
                success: function (response) {
                    if (response) {
                        ajaxResponseContainer.append(response);
                        wrapItemsForAllTabs();
                        page++;
                        isLoading = false;
                        loadMoreButton.text("Load More");
                        if (page == maxPages) {
                            ajaxResponseContainer.addClass('games__info-last');
                            loadMoreButton.hide();
                        }
                    } else {
                        ajaxResponseContainer.addClass('games__info-last');
                        loadMoreButton.remove();
                    }
                },
            });
        }
    });


    // Wrap items into rows based on the current screen size
    function wrapItems($gamesInfo) {
        const itemsPerRow = getItemsPerRow();
        const $items = $gamesInfo.find('.games__col');

        // Clear the previous container items for this specific tab
        $gamesInfo.find('.container.items').remove();

        // Clear the previous rows for this specific tab
        $gamesInfo.find('.games__row').remove();

        // Wrap items into rows
        let $row;
        for (let i = 0; i < $items.length; i += itemsPerRow) {
            $row = $('<div class="games__row"></div>');
            $items.slice(i, i + itemsPerRow).wrapAll('<div class="container items"></div>');
            $row.append($items.slice(i, i + itemsPerRow).parent());
            $row.appendTo($gamesInfo);
        }

        // Remove empty rows (if any)
        $gamesInfo.children('.games__row').filter(function () {
            return !$(this).children().length;
        }).remove();
    }

    // Get the current screen size and determine the number of items per row
    function getItemsPerRow() {
        const screenWidth = $(window).width();
        if (screenWidth <= 600) {
            return 1;
        } else if (screenWidth <= 1024) {
            return 2;
        } else if (screenWidth <= 1200) {
            return 3;
        } else {
            return 4;
        }
    }

    // Wrap items for each tab on initial page load
    function wrapItemsForAllTabs() {
        $('.supported-games__wrap .tab-content').each(function () {
            const $gamesInfo = $(this).find('.games .games__info');
            wrapItems($gamesInfo);
        });
    }

    // Wrap items for each tab whenever the window is resized (with debounce)
    let resizeTimer;
    $(window).on('resize', function () {
        // Call wrapItemsForAllTabs() whenever the window is resized
        wrapItemsForAllTabs();
        // You can use debouncing to optimize the performance (uncomment the code below if needed)
        // clearTimeout(resizeTimer);
        // resizeTimer = setTimeout(wrapItemsForAllTabs, 100); // Adjust the debounce delay as needed
    });

    // Event listener for tab buttons
    $('body').on('click', '.tab-btn', function () {
        // Remove 'active' class from all tab buttons and add it to the clicked button
        $('.tab-btn').removeClass('active');
        $(this).addClass('active');

        // Get the data attributes from the clicked tab button
        const tab = $(this).data('tab');
        const pageId = $(this).data('page-id');

        // Make an Ajax request to load tab content based on the selected tab
        $.ajax({
            url: ajaxurl, // Replace with the URL of your server-side Ajax handler
            type: 'POST',
            data: {
                action: 'load_tab_content',
                tab: tab,
                page_id: pageId
            },
            success: function (response) {
                // Update the content container with the received data
                $('.supported-games__wrap').html(response);

                // Call wrapItemsForAllTabs() after content is loaded to wrap items in rows
                wrapItemsForAllTabs();
                // Scroll to the content container after loading the data
                $('html, body').animate({
                    scrollTop: $('.supported-games__tabs').offset().top - 35 // Scroll to the top position of the loaded content container
                }, 500);
            },
            error: function (xhr, status, error) {
                // Handle errors if needed
                console.error(error);
            }
        });
    });

    const searchInputGames = $('#search-input-supported-games');
    const gemesContainer = $('#supported-games__result');
    let selectedGamesIndex = -1;
    searchInputGames.on('input', function () {
        const searchGamesTerm = $(this).val().trim();
        console.log(searchGamesTerm);
        selectedGamesIndex = -1; // Reset the selected gemesResult index
        // Clear previous gemesResults
        gemesContainer.empty();

        if (searchGamesTerm.length >= 2) {
            $.ajax({
                url: ajaxurl,
                method: 'POST',
                data: {
                    action: 'games_search',
                    search_games_term: searchGamesTerm,
                },
                dataType: 'json',
                success: function (data) {
                    displaySearchGames(data);
                    // gemesContainer.hide();
                },
                error: function (xhr, status, error) {
                    console.error(error);
                }
            });
        } else {
            gemesContainer.hide();
            gemesContainer.parent().hide();
        }
    });

    function displaySearchGames(gemesResults) {
        gemesContainer.empty();

        if (gemesResults.length === 0) {
            // If no gemesResults are found, display a message
            gemesContainer.append('<li class="search-list-games">No results found</li>');
            gemesContainer.show();
            gemesContainer.parent().show();
        } else {
            gemesResults.forEach(function (gemesResult, index) {
                // Use data attribute to store the ID in the list item
                const listItem = $('<li class="search-list-games" data-result="parrent-' + gemesResult.parent_id + '-item-' + gemesResult.nested_id + '"><a href="#parrent-' + gemesResult.parent_id + '-item-' + gemesResult.nested_id + '">' + gemesResult.name + '</a></li>');
                gemesContainer.append(listItem);
            });

            gemesContainer.show();
            gemesContainer.parent().show();
        }
    }

    function selectGemes(index) {
        // Retrieve all the search gemesResults
        const gemesResultItems = gemesContainer.find('.search-list-games');

        // Check if the index is within the range of available gemesResults
        if (index >= 0 && index < gemesResultItems.length) {
            // Remove the "selected" class from all gemesResults
            gemesResultItems.removeClass('selected');

            // Add the "selected" class to the specified gemesResult
            $(gemesResultItems[index]).addClass('selected');

            // Scroll to the selected gemesResult if it is out of view
            const selectedGames = $(gemesResultItems[index]);
            const containerHeight = gemesContainer.height();
            const gemesResultHeight = selectedGames.outerHeight();
            const gemesResultTop = selectedGames.position().top;
            const gemesResultBottom = gemesResultTop + gemesResultHeight;

            if (gemesResultTop < 0 || gemesResultBottom > containerHeight) {
                gemesContainer.scrollTop(gemesContainer.scrollTop() + gemesResultTop);
            }

            // Update the selectedGamesIndex
            selectedGamesIndex = index;
        }
    }

    const fixedHeaderHeight = 92; // Change this value according to your fixed header height
    function removeHash() {
        history.pushState("", document.title, window.location.pathname + window.location.search);
    }

    $('body').on('click', '.search-list-games', function () {
        const gemesResultID = $(this).data('result'); // Retrieve the ID from the data attribute

        // Scroll to the element with the matching ID
        const targetElement = $('#' + gemesResultID);
        searchInputGames.val('');
        gemesContainer.hide()
        gemesContainer.parent().hide();
        if (targetElement.length) {
            if (targetElement.hasClass('active')) {
                $('html, body').animate({
                    scrollTop: targetElement.offset().top - fixedHeaderHeight // Adjust the offset as needed
                }, 1000, function () {
                    removeHash(); // Remove hash from URL after scroll
                }); // Scroll with animation
            } else {
                // Close all other accordion items and slide up their content
                $('.active').removeClass('active').find('.games__col');
                // Add the class to the target element
                targetElement.addClass('active');
                $('html, body').animate({
                    scrollTop: targetElement.offset().top - fixedHeaderHeight // Adjust the offset as needed
                }, 1000, function () {
                    removeHash(); // Remove hash from URL after scroll
                }); // Scroll with animation and remove hash from URL after scroll
            }
        }
    });
    // Keyboard navigation for search suggestions
    searchInputGames.on('keydown', function (event) {
        const keyCode = event.keyCode;

        if (keyCode === 40) {
            // Down arrow key
            event.preventDefault();
            selectGamesIndex = Math.min(selectedGamesIndex + 1, gemesContainer.find('.search-list-games').length - 1);
            selectGames(selectedGamesIndex);
        } else if (keyCode === 38) {
            // Up arrow key
            event.preventDefault();
            selectGamesIndex = Math.max(selectedGamesIndex - 1, -1);
            selectGames(selectedGamesIndex);
        } else if (keyCode === 13) {
            // Enter key
            event.preventDefault();
            const selectedGames = gemesContainer.find('.search-list-games.selected');
            if (selectedGames.length) {
                selectedGames.trigger('click');
            }
        }
    });

    function toggleGames() {
        $('.single-supported-games .supported-games__wrap .tab-content').each(function () {
            var container = $(this);
            var rows = container.find('.games__row');
            rows.hide().slice(0, 5).show();
            if (rows.length > 5) {
                container.find('.game-load-more-container').show();
            }
        });
    }

    if ($(window).width() <= 600) {
        toggleGames();
    }

    $(window).on('resize', function () {
        if ($(window).width() <= 600) {
            toggleGames();
        } else {
            $('.single-supported-games .supported-games__wrap .game-load-more-container').hide();
        }
    });
 
    // Load more button click event
    $('.single-supported-games .supported-games__wrap .game-load-more').on('click', function (event) {
        event.stopPropagation(); // Prevent event propagation to prevent collapse on outside click
        var container = $(this).closest('.tab-content');
        var rows = container.find('.games__row:hidden');
        rows.slice(0, 5).show();
        if (container.find('.games__row:hidden').length === 0) {
            $(this).parent().hide();
            container.find('.games__info').addClass('hide-after-bg');
        }
    });

    // Handle outside click to close opened ones
    $(document).on('click', function (event) {
        var target = $(event.target);
        if (!target.closest('.game-load-more-container').length) {
            $('.game-load-more-container').hide();
        }
    });

});