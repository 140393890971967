import Masonry from 'masonry-layout';

export default function initializeMasonry() {
    const grid = document.querySelector('.reviews-grid');

    // Check if the grid element exists before initializing Masonry
    if (grid) {
        // Initialize Masonry
        new Masonry(grid, {
            itemSelector: '.item',
            // Other options here...
        });
    }
}