import $, { css, get } from "jquery";
$(function ($) {
    const searchInput = $('#search-input');
    const suggestionsContainer = $('#search-suggestions');
    let selectedSuggestionIndex = -1;
    searchInput.on('input', function () {
        const searchTerm = $(this).val().trim();
        selectedSuggestionIndex = -1; // Reset the selected suggestion index
        // Clear previous suggestions
        suggestionsContainer.empty();

        if (searchTerm.length >= 2) {
            $.ajax({
                url: ajaxurl,
                method: 'POST',
                data: {
                    action: 'search_autocomplete',
                    search_term: searchTerm,
                },
                dataType: 'json',
                success: function (data) {
                    displaySearchSuggestions(data);
                },
                error: function (xhr, status, error) {
                    console.error(error);
                }
            });
        } else {
            suggestionsContainer.hide();
            suggestionsContainer.parent().hide();
            suggestionsContainer.hide().parent().parent().removeClass('result-open');
        }
    });

    function displaySearchSuggestions(suggestions) {
        suggestionsContainer.empty();
    
        const searchTerm = searchInput.val().trim();
        const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const searchTermRegex = new RegExp("(" + escapedSearchTerm + ")", "gi");
        
    
        if (suggestions.length === 0) {
            // If no suggestions are found, display a message
            suggestionsContainer.append('<li class="search-list">No results found</li>');
            suggestionsContainer.show().parent().parent().addClass('result-open');
            suggestionsContainer.parent().show();
        } else {
            suggestions.forEach(function (suggestion, index) {
                // Highlight the matched word in a different color
                const highlightedName = suggestion.name.replace(searchTermRegex, '<span class="highlight">$1</span>');
                const listItem = $('<li class="search-list" data-id="' + suggestion.id + '"><a href="#item-' + suggestion.id + '">' + highlightedName + '</a></li>');
                suggestionsContainer.append(listItem);
            });
    
            suggestionsContainer.show().parent().parent().addClass('result-open');
            suggestionsContainer.parent().show();
        }
    }
    

    function selectSuggestion(index) {
        // Retrieve all the search suggestions
        const suggestionItems = suggestionsContainer.find('.search-list');

        // Check if the index is within the range of available suggestions
        if (index >= 0 && index < suggestionItems.length) {
            // Remove the "selected" class from all suggestions
            suggestionItems.removeClass('selected');

            // Add the "selected" class to the specified suggestion
            $(suggestionItems[index]).addClass('selected');

            // Scroll to the selected suggestion if it is out of view
            const selectedSuggestion = $(suggestionItems[index]);
            const containerHeight = suggestionsContainer.height();
            const suggestionHeight = selectedSuggestion.outerHeight();
            const suggestionTop = selectedSuggestion.position().top;
            const suggestionBottom = suggestionTop + suggestionHeight;

            if (suggestionTop < 0 || suggestionBottom > containerHeight) {
                suggestionsContainer.scrollTop(suggestionsContainer.scrollTop() + suggestionTop);
            }

            // Update the selectedSuggestionIndex
            selectedSuggestionIndex = index;
        }
    }

    $(document).on('click', '.search-list', function () {

        // const suggestionText = $(this).text();
        const suggestionID = $(this).data('id'); // Retrieve the ID from the data attribute

        // Reset the input field after clicking the suggestion
        searchInput.val('');
        suggestionsContainer.hide();
        suggestionsContainer.hide().parent().parent().removeClass('result-open');
        suggestionsContainer.parent().hide();
        // Scroll to the element with the matching ID
        const targetElement = $('#' + 'item-' + suggestionID);
        if (targetElement.length) {
            // Check if the target element is already expanded
            if (targetElement.hasClass('active')) {
                $('html, body').animate({
                    scrollTop: targetElement.offset().top - 92// Adjust the offset as needed
                }, 500);
            } else {
                // Close all other accordion items and slide up their content
                $('.active').removeClass('active').find('.accordion__content').slideUp();
                // Add the class to the target element and slide down its content
                targetElement.addClass('active').find('.accordion__content').slideDown(500, function () {
                    // After sliding down, scroll to the target element
                    $('html, body').animate({
                        scrollTop: targetElement.offset().top - 20 // Adjust the offset as needed
                    }, 500);
                });
            }
        }
    });
    // Keyboard navigation for search suggestions
    searchInput.on('keydown', function (event) {
        const keyCode = event.keyCode;

        if (keyCode === 40) {
            // Down arrow key
            event.preventDefault();
            selectedSuggestionIndex = Math.min(selectedSuggestionIndex + 1, suggestionsContainer.find('.search-list').length - 1);
            selectSuggestion(selectedSuggestionIndex);
        } else if (keyCode === 38) {
            // Up arrow key
            event.preventDefault();
            selectedSuggestionIndex = Math.max(selectedSuggestionIndex - 1, -1);
            selectSuggestion(selectedSuggestionIndex);
        } else if (keyCode === 13) {
            // Enter key
            event.preventDefault();
            const selectedSuggestion = suggestionsContainer.find('.search-list.selected');
            if (selectedSuggestion.length) {
                selectedSuggestion.trigger('click');
            }
        }
    });
});